<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-layout
            v-for="(field, i) in fields"
            :key="i"
            column
          >
            <v-flex>
              <component
                :is="field.component"
                :field="field"
                :step="step"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-alert
        v-if="status"
        :color="status"
        :icon="icon"
        :value="true"
        class="mt-3 mx-1"
      >
        {{ alertText }}
      </v-alert>
    </v-card>
    <v-card class="transparent elevation-0">
      <v-btn
        :loading="loading"
        :disabled="loading"
        :block="block"
        color="primary"
        @click="nextStep(formIndex + 1)"
      >
        {{ getButtonText(formIndex + 1) }}
        <span
          slot="loader"
          class="custom-loader"
        >
          <v-icon light>cached</v-icon>
        </span>
      </v-btn>
      <v-btn
        v-if="formIndex > 0"
        :disabled="loading"
        :block="block"
        flat
        @click="prevStep(formIndex + 1)"
      >
        Back
      </v-btn>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="error"
      top
    >
      {{ alertText }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    formIndex: {
      type: Number,
      required: true,
    },
    formStep: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      alertText: 'Please clear all errors before continuing.',
      inputsTimeout: null,
      snackbar: false,
      status: null,
      submitCount: 0,
      timeout: 3000,
      valid: false,
    }
  },
  computed: {
    block: function () {
      return this.$vuetify.breakpoint.xsOnly
    },
    color: function () {
      if (this.status === 'success') return 'success'
      if (this.status === 'info') return 'info'
      return 'error'
    },
    fields: function () {
      return this.formStep.fields
    },
    fieldCount: function () {
      return Object.keys(this.fields).length
    },
    icon: function () {
      if (this.status === 'success') return 'check_circle'
      if (this.status === 'info') return 'info'
      return 'error'
    },
    loading: function () {
      return this.submit
    },
    isImpersonated: function () {
      return this.$store.state.security.impersonate !== null
    },
  },
  watch: {
    submit: function (val) {
      if (!val && this.submitCount) {
        if (this.formStep.status === 'success' || this.formStep.status === 'info') {
          this.$emit('update:step', 23)
        } else if (this.formStep.status === 'warning') {
          this.alertText = this.formStep.deferMessage
          this.status = 'warning'
        } else if (this.formStep.status === 'error') {
          this.alertText = this.formStep.errorMessage
          this.status = 'error'
        }
      }
    },
  },
  methods: {
    nextStep (n) {
      if (!this.validStep()) {
        this.snackbar = true
        return
      }
      if (this.formStep.validateNext) {
        this.status = null
        this.formStep.validateNext()
          .then(respnse => {
            if (Object.prototype.hasOwnProperty.call(this.formStep, 'submit')) {
              this.$emit('update:submit', true)
              this.submitCount++
            } else this.$emit('update:step', n + 1)
          })
          .catch(error => {
            this.alertText = error.response.data
            this.status = 'error'
          })
      } else {
        if (Object.prototype.hasOwnProperty.call(this.formStep, 'submit')) {
          this.$emit('update:submit', true)
          this.submitCount++
        } else this.$emit('update:step', n + 1)
      }
    },
    prevStep (n) {
      this.step > 0 && this.$emit('update:step', this.step - 1)
    },
    getButtonText (n) {
      return Object.prototype.hasOwnProperty.call(this.formStep, 'submit') ? (this.isImpersonated ? 'Emulate Submit' : 'Submit') : 'Continue'
    },
    validStep () {
      let invalid = 0

      Object.values(this.fields).forEach(field => {
        if (Object.prototype.hasOwnProperty.call(field, 'validate')) {
          if (!field.validate(true) || field.error) {
            invalid++
          }
        }
      })

      return invalid === 0
    },
  },
}
</script>
